.container-notFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.container-notFound img {
  width: 400px;
}
.container-notFound div {
  margin-top: 10px;
  font-size: 1.7rem;
}

@media screen and (max-width: 768px) {
  .container-notFound img {
    width: 300px;
  }
}

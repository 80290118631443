header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary);
  box-shadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.6);
}

header .logo {
  max-width: 400px;
}
header .logo > img {
  display: block;
  height: calc(58px + 1rem);
  margin: -0.5rem 0;
}

.header-action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-action > button {
  background: var(--danger);
  color: #fff;
  height: 30px;
  border: none;
  padding: 0rem 0.7rem;
  border-radius: 3px;
  cursor: pointer;
  transition: background 0.3s;
}

.header-action > button:hover {
  background: var(--danger-dark);
}

.header-action > button:active {
  background: var(--danger-darker);
}
.container-full {
  padding: 1rem;
}

.accordion-button::before {
  flex-shrink: 0;
  padding: 0 20px;
  width: 1.25rem;
  margin-left: 0 !important;
  height: 1.25rem;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 1px 5px 1px #dddddd;
  border-radius: 3px;
}

.content-item-paid {
  display: flex;
  width: 100%;

  align-items: center;
  border-radius: 3px;
  justify-content: center;
}

.content-title {
  background-color: #198754;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 3px 3px 0px 0px;
}

.content-body {
  padding: 1rem;
}

.content-action-item-menu {
  position: relative;
}

.content-action-item-menu button:not(.open) ~ div {
  display: none;
}

.content-action-item-inner {
  text-transform: capitalize;
  position: absolute;
  right: 0;
  top: calc(100% + 2px);
  border-radius: 3px;
  background: #fff;
  overflow: hidden;
  box-shadow: 3px 3px 10px 0px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.content-action-item-inner a {
  background: none;
  display: block;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-size: 0.8rem;
  transition: background 0.3s;
}

.content-action-item-inner a:hover {
  background: rgba(0, 0, 0, 0.07);
}

.content-action-item-inner a + a {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}

#btn {
  background-color: var(--success);
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: #fff;
}

.installment-item-pop {
  display: flex;
  flex: 0;
  width: 100%;
  height: 50%;
  flex-direction: row-reverse;
}

.installment-item-pop > div {
  margin-top: -13px;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  background-color: #198754;
  width: 180px;
  border-radius: 0 0px 24px;
}

.pop-bg-renegociada {
  background-color: #dc3545 !important;
}

.installment-item-pagas {
  display: flex;
  /* justify-content: space-around; */
  align-content: stretch;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex: 1;
}

.installment-item-pagas > div {
  flex-wrap: wrap;
  font-weight: 600;
  display: flex;
  padding-right: 40px;
  flex-direction: column;
  text-align: center;
}

.installment-item-pagas > div > strong {
  font-weight: 600;
  color: #777;
  font-size: 11px;
  text-transform: uppercase;
}

.action-back-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 520px) {
  .installment-item-pagas {
    flex-wrap: wrap;
    flex-direction: column;
    height: 90px;
  }

  .content-item-paid {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .installment-item-pagas {
    flex-wrap: wrap;
    flex-direction: column;
    height: 90px;
  }

  .installment-item-pagas > div {
    padding-right: 0;
  }

  .content-item-paid {
    height: auto;
  }
}

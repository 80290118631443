.bg-login {
  width: 100%;
  height: 100%;
}

.container-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.container-logo {
  padding: 20px;
}

.container-wrapper {
  width: 400px;
  height: auto;
}

.container-form {
  text-align: left;
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

label {
  display: block;
}

.DatePicker {
  margin-top: 8px;
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
}

input,
.DatePicker > input {
  text-align: left;
  margin-top: 8px;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

input:focus {
  color: #495057;
  background-color: #fff;
  border-color: var(--success);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.container-action {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

button[type='submit'] {
  width: 100%;
  padding: 0.7rem;
  color: #ffffff;
  background: var(--success);
  border: none;
  border-top: 1px solid var(--success-dark);
  border-radius: 4px;
  transition: background 0.4s;
  cursor: pointer;
}

button[type='submit']:hover {
  background: var(--success-dark);
}

button[type='submit']:active {
  background: var(--success-darker);
}

.whatsapp-fab {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 999;
  cursor: pointer;
}

.whatsapp-fab img {
  width: 46px;
  height: 46px;
}

@media screen and (max-width: 540px) {
  .container-form {
    margin: 0 35px;
  }

  .whatsapp-fab {
    bottom: 10px;
    right: 10px;
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading p {
  margin-top: 30px;
}

.spinner {
  width: 64px;
  height: 64px;
}

.container-home {
  z-index: 999;
  padding-top: 40px;
  height: 70vh;
  background-repeat: no-repeat;
  background-size: contain;
}

.container-home h1 {
  text-align: center;
  color: #157347;
  font-weight: 700;
}

.main {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 0.3fr;
  height: 65vh;
}

.main-left {
  padding: 2rem;
  text-align: center;
}

.main-right {
  display: flex;
  padding: 2rem;
  height: 70vh;
}

.main-buttons {
  background-image: url('/src/assets/carinha.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  background-size: 25%;
}

.container-buttons {
  border-top: 1px solid #aaa;
  padding-top: 10px;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.container-buttons button {
  border: none;
  height: 10vh;
  width: 68%;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 20px;
}

.container-buttons button:hover {
  color: white;
}

.container-buttons button:nth-child(1):hover {
  background-color: #bb2d3b;
}

.container-buttons button:nth-child(2):hover {
  background-color: #157347;
}

.container-buttons button:nth-child(3):hover {
  background-color: #0d6efd;
}

.main-left h1 {
  color: #157347;
}

/*.main-buttons {
 height: 70%;
}*/

.main-center {
  /* border-left: 1px solid #aaa; */
  padding: 2rem;
}

.list-wrapper {
  max-width: 40vw;
  max-height: 64vh;
  overflow-y: auto;
}

/* width */
.list-wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.list-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.list-wrapper::-webkit-scrollbar-thumb {
  background: #157347;
  border-radius: 10px;
}

.card-message:hover {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 575px) {
  .main {
    grid-template-columns: 1fr;
  }
  .list-wrapper {
    max-width: 100vw;
    overflow-y: none;
    height: auto;
  }
}

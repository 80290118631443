:root {
  --primary: hsl(202, 97%, 15%);
  --primary-dark: hsl(202, 97%, 25%);
  --primary-darker: hsl(202, 97%, 35%);

  --danger: hsl(4, 90%, 58%);
  --danger-dark: hsl(4, 90%, 48%);
  --danger-darker: hsl(4, 90%, 44%);

  --success: hsl(134, 61%, 41%);
  --success-dark: hsl(134, 61%, 34%);
  --success-darker: hsl(134, 61%, 30%);
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Open-sans', Sans-serif, Verdana, Geneva, Tahoma, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
textarea,
input {
  font: inherit;
}

body,
html,
.App,
#root {
  width: 100%;
  height: 100%;
}
